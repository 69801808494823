import React from "react"
import "../../styles/case-results.css"
import Heading from "../modules/heading"
import ResultsSlider from "../modules/results-slider"
import { graphql, StaticQuery } from "gatsby"

const CaseResults = ({ centered }) => (
  <StaticQuery
    query={graphql`
      query {
        allWp {
          nodes {
            caseResults {
              cr {
                crHeading
                crResults {
                  number
                  magnitude
                  aop
                }
                crLink
              }
            }
          }
        }
      }
    `}
    render={data => {
      const fields = data.allWp.nodes[0].caseResults.cr
      return (
        <section className={centered ? "cr centered" : "cr"}>
          <Heading size="2.75rem" centered={centered}>
            {fields.crHeading}
          </Heading>
          <ResultsSlider slides={fields.crResults} link={fields.crLink} />
        </section>
      )
    }}
  />
)

export default CaseResults
