import React from "react"
import tw, { styled, css } from "twin.macro"
import Heading from "./heading"
import Marquee from "react-marquee-slider"
// import Swiper from "react-id-swiper"
// import SwiperCore, { Autoplay } from "swiper"
// import "swiper/swiper-bundle.min.css"

const Result = styled.a(() => [
  tw`font-bold flex flex-col -mx-4 items-center justify-center relative text-center cursor-pointer transition-all duration-500 ease-in-out`,
  css`
    height: 400px;
    width: 400px;
    border-radius: 50%;
    border: 2px solid #303234;
    &:focus {
      outline: none;
    }
    h2 {
      ${tw`mt-6`}
      span {
        ${tw`text-marrone-primary`}
      }
    }

    &:hover {
      background: #303234;
    }

    @media (max-width: 1280px) {
      ${tw`-mx-2`}
      height: 275px;
      width: 275px;
    }

    @media (max-width: 640px) {
      ${tw`-mx-2`}
      height: 200px;
      width: 200px;

      h2 {
        ${tw`text-3xl`}
      }
    }
  `,
])

const StyleProvider = styled.div(() => [
  css`
    [direction="rtl"] {
      min-width: inherit !important;
    }
  `,
])

const Magnitude = styled.p(() => [
  tw`font-bold -mt-4 text-marrone-primary font-mont tracking-wide uppercase`,
  css`
    @media (max-width: 640px) {
      ${tw`text-xs -mt-6`}
    }
  `,
])
const Amount = tw(Heading)`leading-tight`

const ResultsSlider = ({ slides, link }) => {
  const listSlides = slides.map(({ number, magnitude, aop }, index) => (
    <Result key={index} href={link}>
      <Amount size="4rem">{`$${number}<span>${magnitude
        .charAt(0)
        .toUpperCase()}</span>`}</Amount>
      <Magnitude>{aop ? aop : magnitude}</Magnitude>
    </Result>
  ))
  return (
    <StyleProvider>
      <Marquee velocity={50}>{listSlides}</Marquee>
    </StyleProvider>
  )
}

export default ResultsSlider
